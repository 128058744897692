import React, { useEffect } from "react";
import { Autocomplete, Button, Grid, Paper, Toolbar } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import CommonForm from "../../utils/components/commonForm";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";

const EditTransformerTypes = () => {
  const [inputStates, setInputStates] = React.useState({});
  const params = useParams();

  const updateInputObject = (key, value) => {
    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    inputStates.transformerCapacity=`${inputStates.wound}${inputStates.kva}`
    if (params.id) {
      axiosWithToken
        .patch(url + `transformerTypes/${params.id}`, inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("TransformerTypes edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axiosWithToken
        .post(url + "transformerTypes", inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("TransformerTypes created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const fetchTransformerTypes = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `transformerTypes/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        let newData = {
          ...inputStates,
          ...dataObject,
        };
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) fetchTransformerTypes();
  }, [params.id]);

  const inputFields = [

    { type: "TextField", label: "Wound", stateKey: "wound" },
    { type: "TextField", label: "KVA", stateKey: "kva" },
    // { type: "TextField", label: "transformerCapacity", stateKey: "transformerCapacity" },
    { type: "TextField", label: "SGST Rate", stateKey: "phase" },
    {
      type: "TextField",
      label: "Store Issue Rate(RS)",
      stateKey: "storeIssueRate",
    },
    {
      type: "TextField",
      label: "Economical(%)",
      stateKey: "economical",
    },
    {
      type: "TextField",
      label: "Max Limit(RS)",
      stateKey: "maxLimit",
    },
    {
      type: "TextField",
      label: "HV Coil",
      stateKey: "hvCoil",
    },
    { type: "TextField", label: "HV Coil Weight", stateKey: "hvCoilWeight" },
    { type: "TextField", label: "LV Coil", stateKey: "lvCoil" },
    { type: "TextField", label: "LV Coil Weight", stateKey: "lvCoilWeight" },
    { type: "TextField", label: "TN Store No", stateKey: "tnStoreNo" },
    { type: "TextField", label: "MP Unit", stateKey: "mpunit" },
    { type: "TextField", label: "Is Rate Contract", stateKey: "isRateContract" },
  ];

  return (
    <>
      <Paper sx={{ mt: 2, p: 4 }}>
        <CommonForm
          inputStates={inputStates}
          updateInputObject={updateInputObject}
          inputFields={inputFields}
        />

        <Toolbar
          style={{
            padding: "0px",
            overflow: "auto",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {params.id ? "Update" : "Save"}
          </Button>
        </Toolbar>
      </Paper>
    </>
  );
};

export default EditTransformerTypes;
