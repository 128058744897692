import React, { useState } from "react";
import Box from "@mui/material/Box";
import { HashRouter, Routes, Route } from "react-router-dom";

import Navbar from "./../common/navbar";
import Dashboard from "./../dashboard/Dashboard";
import Login from "./../login/Login";
import { ToastContainer } from "react-toastify";

import UserList from "../user/userList";
import EditUser from "../user/editUser";
import CompetentMasterList from "../competentMaster/competentMasterList";
import EditCompetentMaster from "../competentMaster/editCompetentMaster";

import ClientsList from "../clients/clientsList";
import EditClient from "../clients/editClient";
import CircleList from "../circles/circleList";
import EditCircle from "../circles/editCircle";
import PartList from "../parts/partList";
import EditParts from "../parts/editParts";
import ServiceList from "../part&service/serviceList";
import EditService from "../part&service/editService";
import WeightAndDeductionList from "../weight&Deduction/weightAndDeductionList";
import EditWeightAndDeduction from "../weight&Deduction/editWeightAndDeduction";
import EditTransactionControl from "../transactionControl/editTransactionControl";
import TransformerTypeList from "../transformerType/transformerTypeList";
import EditTransformerType from "../transformerType/editTransformerType";
import TransactionControlLots from "../transactionControlLots/transactionControlLots";
import TransactionControlLotsList from "../transactionControlLots/transactionControlLotsList";

export default function Main(props) {
  const [title, setTitle] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );

  let PropsMode = props.mode;
  let PropsSetMode = props.setMode;
  let PropsSetThemecolor = props.setThemecolor;

  const routes = [
    {
      path: "/",
      element: localStorage.getItem("isLoggedIn") ? (
        <Dashboard />
      ) : (
        <Login setIsLoggedIn={setIsLoggedIn} setLoader={props.setLoader} />
      ),
    },

    {
      path: "/dashboard",
      element: <Dashboard />,
    },

    {
      path: "/competentMasterList",
      element: <CompetentMasterList setLoader={props.setLoader} />,
    },
    {
      path: "/editCompetentMaster",
      element: <EditCompetentMaster setLoader={props.setLoader} />,
    },
    {
      path: "/editCompetentMaster/:id",
      element: <EditCompetentMaster setLoader={props.setLoader} />,
    },

    {
      path: "/clientsList",
      element: <ClientsList setLoader={props.setLoader} />,
    },
    {
      path: "/editClient",
      element: <EditClient setLoader={props.setLoader} />,
    },
    
    {
      path: "/editClient/:id",
      element: <EditClient setLoader={props.setLoader} />,
    },


    {
      path: "/circleList",
      element: <CircleList setLoader={props.setLoader} />,
    },
    {
      path: "/editCircle",
      element: <EditCircle setLoader={props.setLoader} />,
    },
    
    {
      path: "/editCircle/:id",
      element: <EditCircle setLoader={props.setLoader} />,
    },


    {
      path: "/partList",
      element: <PartList setLoader={props.setLoader} />,
    },
    {
      path: "/editParts",
      element: <EditParts setLoader={props.setLoader} />,
    },
    
    {
      path: "/editParts/:id",
      element: <EditParts setLoader={props.setLoader} />,
    },


    {
      path: "/serviceList",
      element: <ServiceList setLoader={props.setLoader} />,
    },
    {
      path: "/editService",
      element: <EditService setLoader={props.setLoader} />,
    },
    
    {
      path: "/editService/:id",
      element: <EditService setLoader={props.setLoader} />,
    },


    {
      path: "/weightAndDeductionList",
      element: <WeightAndDeductionList setLoader={props.setLoader} />,
    },
    {
      path: "/editWeightAndDeduction",
      element: <EditWeightAndDeduction setLoader={props.setLoader} />,
    },
    
    {
      path: "/editWeightAndDeduction/:id",
      element: <EditWeightAndDeduction setLoader={props.setLoader} />,
    },


    {
      path: "/transformerTypeList",
      element: <TransformerTypeList setLoader={props.setLoader} />,
    },
    {
      path: "/editTransformerType",
      element: <EditTransformerType setLoader={props.setLoader} />,
    },
    
    {
      path: "/editTransformerType/:id",
      element: <EditTransformerType setLoader={props.setLoader} />,
    },
    {
      path: "/transactionControl/:id",
      element: <TransactionControlLots setLoader={props.setLoader} />,
    },
    {
      path: "/transactionControlList",
      element: <TransactionControlLotsList setLoader={props.setLoader} />,
    },

    {
      path: "/transactionControl/:id",
      element: <TransactionControlLots setLoader={props.setLoader} />,
    },
    {
      path: "/transactionControl",
      element: <TransactionControlLots setLoader={props.setLoader} />,
    },




    {
      path: "/usersList",
      element: <UserList setLoader={props.setLoader} />,
    },
    {
      path: "/editUser",
      element: <EditUser setLoader={props.setLoader} />,
    },
    {
      path: "/editUser/:id",
      element: <EditUser setLoader={props.setLoader} />,
    },
  ];

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <HashRouter>
        {localStorage.getItem("isLoggedIn") && (
          <Navbar
            setThemecolor={PropsSetThemecolor}
            mode={PropsMode}
            setMode={PropsSetMode}
            title={title}
            setIsLoggedIn={setIsLoggedIn}
          />
        )}

        <Box
          component="main"
          sx={{ flexGrow: 1, pt: 9, width: "100%", height: "100%" }}
        >
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Box>
      </HashRouter>
      <ToastContainer />
    </Box>
  );
}
